import React, {useState} from "react";
import SEO from "../components/seo";
import AboveFold from "../components/aboveFold";
//import StakesBg from "../components/stakesBg";
import ServiceBar from "../components/serviceBar";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import PlanSteps from "../components/planSteps";
import Review from "../components/review";
import reviews from '../../content/review-data.json'; // JSONData at buildtime
import StyledBackgroundSection from "../components/background";
import SectionHeading from "../components/sectionHeading";
import { 
  Button, 
  Container, 
  Row, 
  Col, 
  Card,
  CardGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowAltCircleUp, 
  faArrowAltCircleDown, 
} from '@fortawesome/free-regular-svg-icons';
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const Home = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          contactEmail
          contactPhoneFmt
          contactPhoneUrl
        }
      },
      before1: file(relativePath: { eq: "20200814-01-before.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      after1: file(relativePath: { eq: "20200814-03-after-b-sq.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      rs1: file(relativePath: { eq: "20220706_162804.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      rs2: file(relativePath: { eq: "20220706_132223.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      rs3: file(relativePath: { eq: "20220801_142210.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      rs4: file(relativePath: { eq: "20220804_141437.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      rs5: file(relativePath: { eq: "siding-20221010-0783-157.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      rs6: file(relativePath: { eq: "siding-20221024-113845.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      rs7: file(relativePath: { eq: "siding-20221024-115118.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      other1: file(relativePath: { eq: "20240217_basement1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      before2: file(relativePath: { eq: "0098_kitchen_a_before_1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wip2: file(relativePath: { eq: "0098_kitchen_a_cwip_3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      after2: file(relativePath: { eq: "0098_kitchen_a_done_4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      before3: file(relativePath: { eq: "kitchen_0d_20200930_013934a-before.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      after3: file(relativePath: { eq: "kitchen_0d_20200930_013934b-after.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      value1: file(relativePath: { eq: "flooring1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider1: file(relativePath: { eq: "roofing1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider2: file(relativePath: { eq: "siding7.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider3: file(relativePath: { eq: "roofing5-zoom1.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider4: file(relativePath: { eq: "siding7.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      stakes1: file(relativePath: { eq: "mold2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      certainteed: file(relativePath: { eq: "certainteed.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      gaf: file(relativePath: { eq: "gaf2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      paragraph1: file(relativePath: { eq: "roofing13.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      paragraph2: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);
  
  const [contactTxt] = useState('');
  const review = reviews.shift();
  
  return (
  <Layout>
    <SEO title="Roofing and Siding Contractor | Utica, NY | Rome, NY" />
    <StyledBackgroundSection/>
    <AboveFold
      title='Roofing&nbsp;&&nbsp;Siding'
      subtitle='Your&nbsp;home&nbsp;can&nbsp;look&nbsp;great with&nbsp;a&nbsp;fresh&nbsp;exterior'
      >
      {/* TODO LEADGEN 
      <div className="cg-leadLink">...or learn about <Link to="/common-roofing-and-siding-problems" className='cg-linkLight'>Common Roofing and Siding&nbsp;Problems</Link></div>
      */}
      <br />
      <ServiceBar/>
    </AboveFold>
    
    { review &&
    <div className='cg-sectionLight'>
      <div className='cg-section'>
        <Container fluid>
          <Row key={review.order}>
            <Col style={{ marginTop:'2em' }}>
              <Review 
                title={review.Title} 
                name={review.Name} 
                thumbtack={!! ("Thumbtack" === review.Source)} >
                {review.Comments}
              </Review>
            </Col>
          </Row>
          <Row>
            <Col sm style={{marginTop:'2rem'}}>
              <Button variant='secondary' href="/reviews#main" >More Reviews</Button>
            </Col>
            <Col sm style={{marginTop:'2rem'}}>
              <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    }
    
    <main id='main'>
    {/*
      <StakesBg id='stakes' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid id='cg-stakesBox'>
            <Row>
              <Col>
                <SectionHeading>...but this can Destroy your house</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col sm style={{textAlign:'left'}}>
                <p>Not only are worn roofing and siding ugly, but they also threaten home & health. 
                If the exterior is damaged (or installed incorrectly), moisture can penetrate.</p>
                <p><strong>Moisture</strong> is the <strong>enemy</strong>:</p>
                <ul>
                  <li>Mold & mildew</li>
                  <li>Health hazards</li>
                  <li>Drywall & ceiling damage</li>
                  <li>Electric fire hazards</li>
                  <li>Structure & frame rot</li>
                </ul>
              </Col>
              <Col sm>
                <div role="img" aria-labelledby="stakes1Image">
                  <Img fluid={data.stakes1.childImageSharp.fluid} className="cg-picture"/>
                  <p id='stakes1Image' className='cg-caption'>Mold remediation after water damage</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Delayed maintenance or poor installation <strong>risks&nbsp;thousands</strong>.</p>
                <p>Protect and beautify your home by contacting us for&nbsp;a&nbsp;quote&nbsp;now:</p>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </StakesBg>
      */}
      <div className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col style={{textAlign:'center'}}>
                <SectionHeading>If your house looks worn...</SectionHeading>
                <Card style={{
                  maxWidth:550, 
                  margin: '1rem auto', 
                  borderTopLeftRadius: '0.25rem', 
                  borderTopRightRadius:'0.25rem',
                  boxShadow: '7px 7px 7px #aaa'
                }}>
                  <Card.Header>Before</Card.Header>
                  <Img fluid={data.before1.childImageSharp.fluid} className='card-img card-img-bottom' style={{borderTopLeftRadius:0,borderTopRightRadius:0}}/>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <SectionHeading>You can make it look great:</SectionHeading>
                <Card style={{
                  maxWidth:550, 
                  margin: '1rem auto', 
                  borderTopLeftRadius: '0.25rem', 
                  borderTopRightRadius:'0.25rem',
                  boxShadow: '7px 7px 7px #aaa'
                }}>
                  <Card.Header>After</Card.Header>
                  <Img fluid={data.after1.childImageSharp.fluid} className='card-img card-img-bottom' style={{borderTopLeftRadius:0,borderTopRightRadius:0}}/>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                {/* TODO LEADGEN 
                <Button variant='secondary' href="/common-roofing-and-siding-problems" >Common Roofing and Siding Problems</Button>
                */}
                <Button variant='secondary' href="/portfolio" >Portfolio</Button>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider1.childImageSharp.fluid} />
      
      <div id='value' className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Quality</SectionHeading>
                <p>
                  To make your home <strong>look great</strong>, with 
                  an exterior that <strong>lasts</strong>, call MLB Contracting.
                  You'll get <strong>quality materials</strong> that 
                  are <strong>installed right</strong>.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Card>
                    <Card.Body>
                      <Card.Title>Trusted Materials</Card.Title>
                      <Card.Text>
                        Your home's exterior will be protected for decades with name-brand materials covered by a solid manufacturer warranty.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>Certified Installation</Card.Title>
                      <Card.Text>
                        Good materials don't work if they're installed wrong. We have the training, certification and experience to get your <strong>job done right</strong>.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>A Beautiful Home</Card.Title>
                      <Card.Text>
                        You'll smile at the fresh curb-appeal that will greet you every time you come home. (Your neighbors might thank you, too.)
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            {/*
            <Row>
              <Col>
                <h5 style={{marginTop:'1em'}}>Quality Brands</h5>
                <CardGroup>
                  <Card>
                    <Img fluid={data.certainteed.childImageSharp.fluid} className="card-img"/>
                  </Card>
                  <Card>
                    <Img fluid={data.gaf.childImageSharp.fluid} className="card-img"/>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            */}
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
        
      <Hr img={data.wideDivider2.childImageSharp.fluid} />
      
      <div id='guide' style={{ textAlign:'center' }}  className='cg-sectionShaded'>
        <div className='cg-section'>
          <SectionHeading>Choose the Right Contractor</SectionHeading>
          <Container fluid>
            <Row>
              <Col>
                <p>Many people have questions when choosing a contractor:</p>
                <div style={{textAlign:'left', maxWidth:'28em', margin:'auto'}}>
                  <ul>
                    <li>Will they charge a fair price?</li>
                    <li>Will their work stand the test of time?</li>
                    <li>Will my house look nice when the job is done?</li>
                  </ul>
                </div>
                <p>
                  Unfortunately, we know <strong>these problems are out there</strong>. That's why we've invested our time and money in becoming 
                  certified installers: so you can <strong>continue with confidence</strong>. 
                  It's also why we've collected reviews from our community over 
                  the past {new Date().getFullYear() - 2005} years: so you can 
                  see the quality service you will get when you choose&nbsp;MLB&nbsp;Contracting.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Review title='Outstanding!' name='Tony' thumbtack>
                    Put in new roof, siding, and tiled house. Did an <strong>outstanding job</strong>! Thank&nbsp;you!
                  </Review>
                  <Review title='Done in no time' name='John S.' thumbtack>
                    Replaced my whole roof and siding for a very decent price.
                    Came in, knocked the job off, and was <strong>done in no time</strong>. Excellent&nbsp;work. 
                  </Review>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Review title='Details count...' name='Karen&nbsp;C.'>
                    Great customer service! Very affordable, love the <strong>attention to 
                    detail</strong>. I can’t thank you enough! God bless you guys!  
                    I know who to contact for my&nbsp;next&nbsp;job.
                  </Review>
                  <Review title='As Promised...' name='Tony'>
                    I was so <strong>impressed</strong> with MLB Construction.  
                    We were provided a detailed estimate and they delivered <strong>everything 
                    as promised</strong>. The owner and crew were such professionals. 
                    I <strong>highly recommend</strong>&nbsp;this&nbsp;company.
                  </Review>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <p>You too, can freshen up your home with roofing and siding&nbsp;that&nbsp;lasts. </p>
                <p>To get service like this <FontAwesomeIcon icon={faArrowAltCircleUp} /> contact&nbsp;us!&nbsp;<FontAwesomeIcon icon={faArrowAltCircleDown} /> </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button variant='secondary' href='/reviews#main'>See More Reviews</Button>
              </Col>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider3.childImageSharp.fluid} style={{ maxHeight: '30vh'}} 
        imgStyle={{objectPosition: 'bottom center', top:'initial', bottom:0 }}/>
      
      
      <div id='plan'  className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Problem Solved</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <PlanSteps />
                <Button className="cg-dark" href="#contactForm">Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr/>
      
      <div id='other'  className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Roofing and Siding</SectionHeading>
                <p>Our Specialty Services...</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Img fluid={data.rs5.childImageSharp.fluid} className='cg-picture' style={{maxHeight:350,marginBottom:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col md>
                <Img fluid={data.rs6.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'2rem'}}/>
              </Col>
              <Col md>
                <Img fluid={data.rs3.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col md>
                <Img fluid={data.rs4.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'2rem'}}/>
              </Col>
              <Col md>
                <Img fluid={data.rs1.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col md>
                <Img fluid={data.rs2.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'2rem'}}/>
              </Col>
              <Col md>
                <Img fluid={data.rs7.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="/roofing-contractor" >Roofing</Button>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="/vinyl-siding-contractor" >Siding</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr/>     
      
      <div id='other'  className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Other Services</SectionHeading>
                <p>We do more than Roofing and Siding...</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='cg-caption'>Basement Remodeling</div>
                <Img fluid={data.other1.childImageSharp.fluid} className='cg-picture' style={{maxHeight:350,marginBottom:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col md>
                <hr style={{maxWidth: '8rem', margin: '1rem auto'}}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='cg-caption'>Kitchen Remodeling: before...</div>
                <Img fluid={data.before2.childImageSharp.fluid} className='cg-picture' style={{maxHeight:350,marginBottom:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='cg-caption'>...during...</div>
                <Img fluid={data.wip2.childImageSharp.fluid} className='cg-picture' style={{maxHeight:350,marginBottom:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='cg-caption'>...after!</div>
                <Img fluid={data.after2.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'3rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col md>
                <hr style={{maxWidth: '8rem', margin: '1rem auto'}}/>
              </Col>
            </Row>
            <Row>
              <Col md>
                <div className='cg-caption'>During</div>
                <Img fluid={data.before3.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'2rem'}}/>
              </Col>
              <Col md>
                <div className='cg-caption'>After</div>
                <Img fluid={data.after3.childImageSharp.fluid} className='cg-picture' style={{marginBottom:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <Button variant='secondary' href="/portfolio" >Portfolio</Button>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider4.childImageSharp.fluid} />
      
      <div id='paragraph' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>About MLB&nbsp;Contracting</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-block d-md-none cg-mini-picture-box' style={{minWidth:180}}>
                  <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture'/>
                 </div>
                <p>
                  At MLB Contracting we know you want to feel good about the condition
                  of your house. A big part of this is maintaining the exterior, which protects
                  the structure and interior from water damage. We believe every homeowner 
                  should be able to feel good about the appearance of their home and be
                  confident it will last. This is why we have focused, trained and been certified 
                  to install roofing, vinyl siding, soffit & fascia according to industry 
                  standards. <small>(If your project calls for more, we can help:
                  complete bathrooms & kitchens, windows, doors, tiling, painting, framing, 
                  electrical, heating / air conditioning, finish carpentry, new construction.)</small> We have 
                  a <Link to="/reviews#main" className='cg-linkDark'>track record</Link> that 
                  ensures your success. You can protect your home
                  and also refresh curb-appeal: 
                  1.Get a quote 2.We'll contact you to discuss project details 3.We'll 
                  schedule a start date that works for you. So,  <a href="#contactForm" className='cg-linkDark'>request a quote</a> today. 
                  {/* TODO LEADGEN 
                  And in the meantime, learn 
                  about <Link to="/common-roofing-and-siding-problems" className='cg-linkDark'>common roofing and siding problems</Link>. 
                  */}
                </p>
              </Col>
              <Col md className='d-none d-md-block'>
                {/* <Img fluid={data.paragraph2.childImageSharp.fluid} className='cg-picture'/> */}
                <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture' style={{marginTop:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                {/* TODO LEADGEN 
                <Button variant='secondary' href="/common-roofing-and-siding-problems" >Common Roofing and Siding Problems</Button>
                */}
                <Button variant='secondary' href="/reviews#main" >Reviews</Button>
                {/* TODO LEADGEN replace reviews button above? */}
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider1.childImageSharp.fluid}/>
      
    </main>
    <ContactForm message={contactTxt}/> 
    
    <div id='footnotes' className='cg-sectionLight'>
      <div className='cg-section'>
        <Container fluid>
          <Row>
            <Col style={{ textAlign:'center', color:'#aaa'}}>
              <small>All construction photos are from MLB Contracting projects.</small>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Layout>
)};



export default Home;
