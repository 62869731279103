import React from "react";
import {Container,Row,Col,Card} from "react-bootstrap";
// import Img from "gatsby-image";
import { Link } from "gatsby"
import './serviceBar.css'

const ServiceBar = () => {
  
//   const data = useStaticQuery(graphql`
//   query {
//     toilet: file(relativePath: { eq: "icon-toilet-3.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     },
//     hammer: file(relativePath: { eq: "icon-hammer-3.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     },
//     porta: file(relativePath: { eq: "icon-porta-3.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `);


    return (
      <Container id='cg-serviceBar'>
        <Row>
          <Col xs>
            <Card>
              <Link to='/roofing-contractor'>
                {/*<Img fluid={data.toilet.childImageSharp.fluid}/>*/}
                <h1>Roofing</h1>
              </Link>
            </Card>
          </Col>
          <Col xs>
            <Card>
              <Link to='/vinyl-siding-contractor'>
                <h1>Siding</h1>
              </Link>
            </Card>
          </Col>
          {/*          
          <Col xs>
            <Card>
              <a href='#kitchenbath'>
                <p>Kitchen & Bath</p>
              </a>
            </Card>
          </Col>
          */}
        </Row>
      </Container>
    );
};

export default ServiceBar;